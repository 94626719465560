@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  /* Add this to your CSS file (e.g., App.css) */

  padding-top: 4rem; /* Adjust this value based on your Navbar height */


}

.App {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

h1,
h2,
h3 {
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #1e90ff;
  color: white;
}

.card {
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard {
  display: flex;
  flex-wrap: wrap;
}

.dashboard .card {
  flex: 1;
  min-width: 300px;
}

.student-list,
.teacher-list,
.class-schedule {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Homepage header */
@media screen and (max-width: 768px) {
  .main{
    position: relative;
    height: 0% !important;
    /* display: none; */
  }
  .home_header{
    /* height: 50%; */
    position: absolute;
    background-color: transparent !important;
    top: -0%;
    /* left: 50%; */
    transform: translate(0%,40%);
  }
  .margin{
    margin-top: 8rem !important;
  }
}